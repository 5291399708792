// application.js

// Starting Rails UJS, Turbo, and ActiveStorage
require("@rails/ujs").start();
import "@hotwired/turbo-rails";
require("@rails/activestorage").start();
require("channels");

// Importing Bootstrap and Popper.js
import * as bootstrap from 'bootstrap'; // Ensure bootstrap is correctly imported
console.log('Bootstrap:', bootstrap);

// Importing jQuery and plugins
import $ from 'jquery';
require('jquery-ui-bundle');
import 'select2';
import 'select2/dist/css/select2.css';
import "chartkick/chart.js";
import "controllers";

// Setting global jQuery references
window.jQuery = $;
window.$ = $;

// Initializing Select2 on Turbo load event
document.addEventListener('turbo:load', () => {
  $(".change-into-select2").select2({ theme: "bootstrap", maximumSelectionLength: 5});
  $("#give_away_user").select2({ dropdownParent: $("#giveAwayModal"), theme: "bootstrap" });
  $("#give_away_location").select2({ dropdownParent: $("#giveAwayModal"), theme: "bootstrap" });
  $("#broken_location").select2({ dropdownParent: $("#brokenModal"), theme: "bootstrap" });
});

// Ensure Bootstrap dropdowns are initialized on Turbo load
document.addEventListener('turbo:load', () => {
  console.log('Turbo loaded');
  const dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
  dropdownElementList.map((dropdownToggleEl) => {
    console.log('Initializing dropdown', dropdownToggleEl);
    new bootstrap.Dropdown(dropdownToggleEl)
  });
});

// Importing Trix and ActionText
require("trix");
require("@rails/actiontext");

document.addEventListener('DOMContentLoaded', () => {
  const video = document.querySelector('video');
  if (video) {
    video.controls = true; // Ustawia widoczność kontrolek
    console.log('Kontrolki zostały włączone.');
  }
});
